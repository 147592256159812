import * as ACTION from "./actionTypes.js";

export function getFarms(farms) {
    return { type: ACTION.GET_FARMS, farms };
}

export function selectFarm(farm) {
    return { type: ACTION.SELECT_FARM, farm }
}

export function setCows(cows) {
    return { type: ACTION.SET_COWS, cows }
}
export function selectFile(file) {
    return { type: ACTION.SELECT_FILE, file };
}

export function loadTable(data) {
    return { type: ACTION.LOAD_TABLE, data };
}

export function getCowTypes(types) {
    return { type: ACTION.GET_COW_TYPES, types };
}

export function getPregnancyStatus(pregnancyStatus) {
    return { type: ACTION.GET_PREGNANCY_STATUS, pregnancyStatus };
}

export function dataChange(data) {
    return { type: ACTION.DATA_CHANGE, data };
}

export function manualDataChange(data) {
    return { type: ACTION.MANUAL_DATA_CHANGE, data };
}

export function insertCow(data) {
    return { type: ACTION.INSERT_COW, data };
}

export function updateCow(data) {
    return { type: ACTION.UPDATE_COW, data };
}

export function removeCow(data, shouldRemoved) {
    return { type: ACTION.REMOVE_COW, data, shouldRemoved };
}

export function insertCowSuccess(result) {
    return { type: ACTION.INSERT_COW_SUCCESS, result };
}

export function updateCowSuccess(result) {
    return { type: ACTION.UPDATE_COW_SUCCESS, result };
}

export function removeCowSuccess(result) {
    return { type: ACTION.REMOVE_COW_SUCCESS, result };
}

export function sendEvent(farm) {
    return { type: ACTION.SEND_EVENT, farm };
}

export function setFormat(format) {
    return { type: ACTION.SET_FORMAT, format };
}

export function submitLoginEmail(email) {
    return { type: ACTION.SUBMIT_LOGIN_EMAIL, email };
}
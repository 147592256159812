import { connect } from 'react-redux';
import { setFormat } from '../store/actions';

export function mapStateToProps({ dateFormat }) {
    return {
        dateFormat
    };
};

const mapDispatchToProps = { setFormat };

export default connect(mapStateToProps, mapDispatchToProps);
import { connect } from 'react-redux';
import { selectFile, dataChange } from '../store/actions';

export function mapStateToProps({ dateFormat }){
    return {
        format: dateFormat
    };
}

const mapDispatchToProps = { selectFile, dataChange };

export default connect(mapStateToProps, mapDispatchToProps);
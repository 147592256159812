import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import * as reducers from './reducers';
import createSagaMiddleware from 'redux-saga';
import { getCowTypesRequest, getFarms, farmData, operation, insertCow, updateCow, removeCow, sendEvent, sendEventHandler, startup } from './sagas';

import "./axiosDefaults";
const rootReducer = combineReducers(reducers);

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
    const store = createStore(rootReducer, composeEnhancers(
        applyMiddleware(sagaMiddleware)
    ));
    // sagaMiddleware.run(getFarms);
    sagaMiddleware.run(farmData);
    // sagaMiddleware.run(getCowTypesRequest);
    sagaMiddleware.run(operation);
    sagaMiddleware.run(insertCow);
    sagaMiddleware.run(updateCow);
    sagaMiddleware.run(removeCow);
    sagaMiddleware.run(sendEvent);
    sagaMiddleware.run(sendEventHandler);
    sagaMiddleware.run(startup);

    return store;
}

export default configureStore;
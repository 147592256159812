import {
    START_LOGIN_LINK_REQUEST,
    COMPLETE_LOGIN_LINK_REQUEST,
    FAIL_LOGIN_LINK_REQUEST,
    INIT_LOGIN_EMAIL,
    SKIP_LOGIN_REQUEST,
    COMPLETE_LOGIN_REQUEST,
    FAIL_LOGIN_REQUEST,
    REDIRECT_TO_LOGIN,
} from "../actions/actionTypes";
import { INITIAL, PENDING, DONE, FAILED } from "../../constants/lifecycle";

const initialState = {
    lifecycle: INITIAL,
    loginStatus: null,
};

export default function login(state = initialState, action) {
    switch (action.type) {
        case START_LOGIN_LINK_REQUEST:
            return { ...state, lifecycle: PENDING };
        case COMPLETE_LOGIN_LINK_REQUEST:
            return { ...state, lifecycle: DONE };
        case FAIL_LOGIN_LINK_REQUEST:
            return { ...state, lifecycle: FAILED };
        case INIT_LOGIN_EMAIL:
            return { ...state, lifecycle: INITIAL };
        case COMPLETE_LOGIN_REQUEST:
            return { ...state, loginStatus: "success" };
        case REDIRECT_TO_LOGIN:
            return { ...state, loginStatus: "failed" };
        case SKIP_LOGIN_REQUEST:
            return { ...state, loginStatus: "success" };
        case FAIL_LOGIN_REQUEST:
        default:
            return state;
    }
}

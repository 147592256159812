import React from 'react'
import LoginForm from '../LoginForm'
import './Login.css'

const index = () => {
    return (
        <div className='modal-container'>
            <LoginForm />
        </div>
    )
}

export default index
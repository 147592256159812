// User Preferences
export const SET_FORMAT = "SET_FORMAT";

// Farm Selection
export const GET_FARMS = "GET_FARMS";
export const SELECT_FARM = "SELECT_FARM";

// Database operations
export const INSERT_COW = "INSERT_COW";
export const UPDATE_COW = "UPDATE_COW";
export const REMOVE_COW = "REMOVE_COW";
export const INSERT_COW_SUCCESS = "INSERT_COW_SUCCESS";
export const UPDATE_COW_SUCCESS = "UPDATE_COW_SUCCESS";
export const REMOVE_COW_SUCCESS = "REMOVE_COW_SUCCESS"
export const GET_COW_TYPES = "GET_COW_TYPES";
export const GET_PREGNANCY_STATUS = "GET_PREGNANCY_STATUS";

// File operations
export const SELECT_FILE = "SELECT_FILE";

// Table operations
export const LOAD_TABLE = "LOAD_TABLE";
export const SET_COWS = "SET_COWS";
export const DATA_CHANGE = "DATA_CHANGE";
export const MANUAL_DATA_CHANGE = "MANUAL_DATA_CHANGE"
export const DATA_ADD = "DATA_ADD";
export const DATA_DELETE = "DATA_DELETE";

// Send event
export const SEND_EVENT = "SEND_EVENT";

// Login

export const SET_SINGLE_USE_TOKEN = "SET_SINGLE_USE_TOKEN";
export const START_LOGIN_REQUEST = "START_LOGIN_REQUEST";
export const COMPLETE_LOGIN_REQUEST = "COMPLETE_LOGIN_REQUEST";
export const FAIL_LOGIN_REQUEST = "FAIL_LOGIN_REQUEST";
export const SKIP_LOGIN_REQUEST = "SKIP_LOGIN_REQUEST";
export const INIT_LOGIN_EMAIL = "INIT_LOGIN_EMAIL";
export const SUBMIT_LOGIN_EMAIL = "SUBMIT_LOGIN_EMAIL";
export const START_LOGIN_LINK_REQUEST = "START_LOGIN_LINK_REQUEST";
export const COMPLETE_LOGIN_LINK_REQUEST = "COMPLETE_LOGIN_LINK_REQUEST";
export const FAIL_LOGIN_LINK_REQUEST = "FAIL_LOGIN_LINK_REQUEST";
export const SET_ACTIVE_USER = "SET_ACTIVE_USER";
export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";
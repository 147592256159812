/* eslint-disable import/no-anonymous-default-export */
import { SET_FORMAT } from '../actions/actionTypes';

const initialState = "mm/dd/yyyy";

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_FORMAT:
            return action.format;
        default:
            return state;
    }
}
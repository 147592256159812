/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getFarms, selectFarm, loadTable } from '../../store/actions';
import '../Navigation/Navigation.css';
import './FarmSelect.css';

export function FarmSelect({ farms, selectFarm }) {
  const [totalCows, setTotalCows] = useState('');

  const handleChange = (event) => {
    selectFarm(farms[event.target.value]);
    setTotalCows(farms[event.target.value].totalCows);
  }

  return (
    <div className="farmSelect">
      <select id="farm-select" defaultValue={'default'} onChange={handleChange}>
        <option value="default" disabled hidden>Select farm</option>
        {farms.map((f, index) => (
          <option key={f.id} value={index}>{f.name}</option>
        ))}

      </select>
      <span id="cowCount"><b>{totalCows ? 'Current cow count: ' + totalCows : ''}</b></span>
    </div>
  );
}

function mapStateToProps({ farms }) {
  return {
    farms: farms.farms,
    selectedFarm: farms.selectedFarm
  };
}

const mapDispatchToProps = { getFarms, selectFarm, loadTable };

export default connect(mapStateToProps, mapDispatchToProps)(FarmSelect);
/* eslint-disable import/no-anonymous-default-export */
import { GET_FARMS, SELECT_FARM } from '../actions/actionTypes';

const initialState = {
    farms: [],
    selectedFarm: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FARMS:
            return {...state, farms: action.farms};
        case SELECT_FARM:
            return {...state, selectedFarm: action.farm};
        default:
            return state;
    }
}
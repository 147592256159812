import { put, call, spawn } from 'redux-saga/effects';
import axios from 'axios';
import {
    SKIP_LOGIN_REQUEST,
    COMPLETE_LOGIN_REQUEST,
    FAIL_LOGIN_REQUEST,
    START_LOGIN_REQUEST,
} from "../actions/actionTypes";

import { loginLink } from '.';

export function* login() {
    const currentURL = new URL(window.location);
    const singleUseToken = currentURL.searchParams.get("singleUseToken");
    if (!singleUseToken) {
        yield spawn(checkLTT);
        return;
    }
    yield put({ type: START_LOGIN_REQUEST });
    try {
        const response = yield call(axios.get, "/importer/login", {
            headers: { Authorization: `Bearer ${singleUseToken}` },
        });
        localStorage.setItem("longTermToken", response.data.token);
        yield put({ type: COMPLETE_LOGIN_REQUEST });
    } catch (error) {
        currentURL.searchParams.delete("singleUseToken");
        yield spawn(checkLTT);
    }
}

function* checkLTT() {
    const longTermToken = localStorage.getItem("longTermToken");
    if (!longTermToken) {
        yield put({ type: FAIL_LOGIN_REQUEST });
        yield spawn(loginLink);
        return;
    }
    handleUnauthorized();
    yield put({ type: SKIP_LOGIN_REQUEST, longTermToken });
}


// eslint-disable-next-line require-yield
function* handleUnauthorized() {
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        (err) => handleError(err),
    );
}

function* handleError(error) {
    if (error.response.status === 401) {
        yield localStorage.removeItem("longTermToken");
        window.location.reload();
    }
    return Promise.reject(error);
}
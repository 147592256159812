import { put, take, spawn, call, delay } from 'redux-saga/effects';
import axios from 'axios';

import {
    INIT_LOGIN_EMAIL,
    SUBMIT_LOGIN_EMAIL,
    START_LOGIN_LINK_REQUEST,
    COMPLETE_LOGIN_LINK_REQUEST,
    FAIL_LOGIN_LINK_REQUEST,

} from "../actions/actionTypes";

export function* loginLink() {
    const { email } = yield take(SUBMIT_LOGIN_EMAIL);
    yield put({ type: START_LOGIN_LINK_REQUEST, email });
    try {
        yield call(axios.post, "/importer/login", {
            email: email,
        });
        yield put({ type: COMPLETE_LOGIN_LINK_REQUEST });
    } catch (error) {
        yield put({ type: FAIL_LOGIN_LINK_REQUEST });
    }
    yield delay(6000);
    yield put({ type: INIT_LOGIN_EMAIL });
    yield spawn(loginLink);
}
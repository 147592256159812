import { connect } from 'react-redux';

import { submitLoginEmail } from '../store/actions';

function mapStateToProps({ login, longTermToken }) {
    return {
        lifecycle: login.lifecycle,
        longTermToken
    };
}

const mapDispatchToProps = { submitLoginEmail };

export default connect(mapStateToProps, mapDispatchToProps);

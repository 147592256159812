/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import FarmSelect from '../FarmSelect';
import Upload from '../Upload';
import SelectFile from '../SelectFile';
import DateFormat from '../DateFormat'
import './Navigation.css';

const Navigation = ({ loggedIn, tableStatus }) => {
    return (
        <nav>
            <div className="logo">
                <span>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="48" height="48" rx="8" fill="#2D9CDB" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.8994 20C10.4663 20 6.87252 16.4183 6.87252 12C6.87252 11.3094 6.96032 10.6392 7.1254 10C8.01647 13.4505 11.1592 16 14.8994 16V20ZM32.9598 16H14.8994V21.699C13.5362 20.6346 11.819 20 9.95301 20C6.98194 20 4.38789 21.6088 3 24C4.38789 26.3912 6.98194 28 9.95301 28C11.819 28 13.5362 27.3654 14.8994 26.301V36C14.8994 38.2091 16.6963 40 18.9128 40H28.9464C31.1629 40 32.9598 38.2091 32.9598 36V26.1885C34.3446 27.3206 36.1162 28 38.047 28C41.0181 28 43.6121 26.3912 45 24C43.6121 21.6088 41.0181 20 38.047 20C36.1162 20 34.3446 20.6794 32.9598 21.8115V20C37.3929 20 40.9867 16.4183 40.9867 12C40.9867 11.3094 40.8989 10.6392 40.7338 10C39.8427 13.4505 36.7 16 32.9598 16ZM16.9061 28C16.9061 29.6569 18.2537 31 19.9162 31C21.5786 31 22.9262 29.6569 22.9262 28C22.9262 26.3431 21.5786 25 19.9162 25C18.2537 25 16.9061 26.3431 16.9061 28ZM27.943 31C26.2806 31 24.933 29.6569 24.933 28C24.933 26.3431 26.2806 25 27.943 25C29.6054 25 30.9531 26.3431 30.9531 28C30.9531 29.6569 29.6054 31 27.943 31Z" fill="#F7F7F7" />
                    </svg>
                </span>
                <h1 className="navTitle">wiCow List Importer</h1>

            </div>
            <div className='inputs'>
                <div>
                    {loggedIn && <FarmSelect />}
                </div>
                {loggedIn && (
                    <div>
                        <DateFormat />
                        <Upload tableStatus={tableStatus} />
                        <SelectFile />
                        <a href='how-to-use.html' target="_blank">
                            <div className='question'>
                                <span>?</span>
                            </div>
                        </a>
                    </div>
                )}

            </div>
        </nav>

    );
}

export default Navigation;

import { connect } from 'react-redux';
import { loadTable, manualDataChange } from '../store/actions';

function unixToDateConvert(cows) {
    return cows.map(c => {
        return {
            ...c,
            "birthdate" : c['birthdate'] ? new Date(c['birthdate']).toISOString().slice(0,10) : '',
            "lastBreedingDate" : c['lastBreedingDate'] ?  new Date(c['lastBreedingDate']).toISOString().slice(0,10) : '',
            "lastCalvingDate" : c['lastCalvingDate'] ? new Date(c['lastCalvingDate']).toISOString().slice(0,10) : '',
            "expectedDelivery" : c['expectedDelivery'] ? new Date(c['expectedDelivery']).toISOString().slice(0,10) : '',
        }
    })
}

export function mapStateToProps({ cows }){
    return {
        data: unixToDateConvert(Object.values(cows.data)),
        updates: cows.updates,
        cowTypes: cows.cowTypes,
        duplicates: cows.duplicates,
        removedCows: cows.removedCows
    }
}

const mapDispatchToProps = { loadTable, manualDataChange };

export default connect(mapStateToProps, mapDispatchToProps);
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useLayoutEffect, useRef } from 'react';
import connect from '../../containers/Spreadsheet';
import useWindowDimensions from './windowHeightHook';
import { DataSheetGrid, textColumn, datePickerColumn, selectColumn, checkboxColumn } from 'react-datasheet-grid';
import 'react-datasheet-grid/dist/index.css'
import './Spreadsheet.css';
import * as render from './RenderStyle';
import ReactTooltip from 'react-tooltip';

export function Spreadsheet({ data, updates, cowTypes, manualDataChange, setTableStatus, duplicates, removedCows }) {
  const { height } = useWindowDimensions();

  const columns = [
    textColumn({
      title: 'Kulak No', width: 1, minWidth: 200, key: 'identityNumber', placeholder: 'TR0000000001',
      columnName: 'identityNumber',
      render: ({ rowData, rowIndex, columnIndex, setRowData, focus }) => renderStyle(rowData, rowIndex, columnIndex, setRowData, focus, 'text')
    }),
    textColumn({
      title: 'İnek No', width: 1, minWidth: 200, key: 'farmNumber', placeholder: '0001',
      columnName: 'farmNumber',
      render: ({ rowData, rowIndex, columnIndex, setRowData, focus }) => renderStyle(rowData, rowIndex, columnIndex, setRowData, focus, 'text')
    }),
    textColumn({
      title: 'İnek Tanım', width: 1, minWidth: 200, key: 'name', placeholder: 'Sarıkız',
      columnName: 'name',
      render: ({ rowData, rowIndex, columnIndex, setRowData, focus }) => renderStyle(rowData, rowIndex, columnIndex, setRowData, focus, 'text')
    }),
    selectColumn({
      title: 'İnek Cinsi', width: 1, minWidth: 200, key: 'breed',
      columnName: 'breed',
      selectData: Object.values(cowTypes).map((item) => item['cowTypeName']),
      render: ({ rowData, rowIndex, columnIndex, setRowData, focus }) => renderStyle(rowData, rowIndex, columnIndex, setRowData, focus, 'select')
    }),
    textColumn({
      title: 'İnek Cinsiyeti', width: 1, minWidth: 200, key: 'gender', placeholder: '0',
      columnName: 'gender',
      render: ({ rowData, rowIndex, columnIndex, setRowData, focus }) => renderStyle(rowData, rowIndex, columnIndex, setRowData, focus, 'text')
    }),
    datePickerColumn({
      title: 'Doğum Tarihi', width: 1, minWidth: 200, key: 'birthdate',
      columnName: 'birthdate',
      render: ({ rowData, rowIndex, columnIndex, setRowData, focus }) => renderStyle(rowData, rowIndex, columnIndex, setRowData, focus, 'date')
    }),
    datePickerColumn({
      title: 'Son Buzağılama Tarihi', width: 1, minWidth: 200, key: 'lastCalvingDate',
      columnName: 'lastCalvingDate',
      render: ({ rowData, rowIndex, columnIndex, setRowData, focus }) => renderStyle(rowData, rowIndex, columnIndex, setRowData, focus, 'date')
    }),
    textColumn({
      title: 'Laktasyon Sayısı', width: 1, minWidth: 200, key: 'lactationCount', placeholder: '0',
      columnName: 'lactationCount',
      render: ({ rowData, rowIndex, columnIndex, setRowData, focus }) => renderStyle(rowData, rowIndex, columnIndex, setRowData, focus, 'number')
    }),
    datePickerColumn({
      title: 'Son Tohumlama Tarihi', width: 1, minWidth: 200, key: 'lastBreedingDate',
      columnName: 'lastBreedingDate',
      render: ({ rowData, rowIndex, columnIndex, setRowData, focus }) => renderStyle(rowData, rowIndex, columnIndex, setRowData, focus, 'date')
    }),
    textColumn({
      title: 'PADOK No', width: 1, minWidth: 200, key: 'padokNo',
      columnName: 'padokNo',
      render: ({ rowData, rowIndex, columnIndex, setRowData, focus }) => renderStyle(rowData, rowIndex, columnIndex, setRowData, focus, 'text')
    }),
    checkboxColumn({
      title: 'Gebe', width: 1, minWidth: 200, key: 'isPregnant',
      columnName: 'isPregnant',
      render: ({ rowData, rowIndex, columnIndex, setRowData, focus }) => renderStyle(rowData, rowIndex, columnIndex, setRowData, focus, 'check')
    }),
    textColumn({
      title: 'Üreme Durumu Not', width: 1, minWidth: 200, key: 'breedingStatusNote',
      columnName: 'breedingStatusNote',
      render: ({ rowData, rowIndex, columnIndex, setRowData, focus }) => renderStyle(rowData, rowIndex, columnIndex, setRowData, focus, 'text')
    }),
    datePickerColumn({
      title: 'Doğurma Tarihi', width: 1, minWidth: 200, key: 'expectedDelivery',
      columnName: 'expectedDelivery',
      render: ({ rowData, rowIndex, columnIndex, setRowData, focus }) => renderStyle(rowData, rowIndex, columnIndex, setRowData, focus, 'date')
    }),
  ];

  const handleChange = (data) => {
    manualDataChange(data);
  }

  const columnsIndexes = columns.reduce((acc, c, index) => {
    return {
      ...acc,
      [c.columnName]: index
    }
  }, {});

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  }

  const primaryColumns = render.getPrimaryColumns();
  useLayoutEffect(() => {
    updates.length > 0 || removedCows.length > 0 ? render.showSaveChanges() : render.hideSaveChanges();
    if (data.length > 0) {
      render.showSelectFile();
      render.highlightStop(); // Farm selected
    }
    else {
      render.highlightStart(); // Highlight farm selection
      render.hideSelectFile(); // Prevent file selecting if farm not selected
    }
  });

  let selectData = Object.values(cowTypes).map((item) => item['cowTypeName']);

  setTableStatus('ready');

  const renderStyle = (rowData, rowIndex, columnIndex, setRowData, focus, columnType) => {
    const ref = useRef(null);
    let column = getKeyByValue(columnsIndexes, columnIndex);
    let className = 'no-change';
    useLayoutEffect(() => {
      if (focus) {
        if (columnType === 'text' || columnType === 'date' || columnType === 'number') ref.current?.select();
        if (columnType === 'select') (typeof ref.current?.onselect === 'function') && ref.current?.onselect(rowData[column]);

      } else {
        ref.current?.blur();
      }
    }, [focus]);

    // Class type
    if (rowData.identityNumber) {
      if (primaryColumns.includes(column) && render.hasMissingValue(rowData[column])) {
        setTableStatus('not-ready');
        className = 'missing-value';
      }
      else if (column === 'identityNumber' && duplicates.includes(rowData[column])) {
        setTableStatus('not-ready');
        className = 'duplicated-value';
      }
      else if (updates.length > 0) {
        let cow = updates.find(x => x.cowName === rowData['identityNumber']);
        if (cow) {
          if (cow.newCow) {
            className = 'new-cow';
          } else {
            Object.keys(cow.attributes).includes(column) ? className = 'updated-value' : className = 'no-change';
          }
        }
        else {
          className = 'no-change';
        }
      }
    }

    // Column type
    switch (columnType) {
      case 'text':
        if (className === 'duplicated-value') {
          return (
            <>
              <input data-for={rowIndex + '-' + columnIndex} data-tip ref={ref} className={'dsg-input ' + className} style={{ pointerEvents: focus ? 'auto' : 'none' }} value={rowData[column] ? rowData[column] : ''} onChange={(e) => setRowData({ ...rowData, [column]: e.target.value })} />
              <ReactTooltip id={rowIndex + '-' + columnIndex} place="right" type="warning" effect="solid" backgroundColor='rgb(200, 61, 255);'>
                <span><b>Please avoid duplication!</b></span>
              </ReactTooltip>
            </>
          )
        }
        else if (className === 'missing-value') {
          return (
            <>
              <input data-for={rowIndex + '-' + columnIndex} data-tip ref={ref} className={'dsg-input ' + className} style={{ pointerEvents: focus ? 'auto' : 'none' }} value={rowData[column] ? rowData[column] : ''} onChange={(e) => setRowData({ ...rowData, [column]: e.target.value })} />
              <ReactTooltip id={rowIndex + '-' + columnIndex} type="error" effect="solid">
                <span><b>Please fill this cell</b></span>
              </ReactTooltip>
            </>
          )
        } else {
          return (<input ref={ref} className={'dsg-input ' + className} style={{ pointerEvents: focus ? 'auto' : 'none' }} value={rowData[column] ? rowData[column] : ''} onChange={(e) => setRowData({ ...rowData, [column]: e.target.value })} />);
        }
      case 'date':
        if (className === 'missing-value') {
          return (
            <>
              <input data-for={rowIndex + '-' + columnIndex} data-tip ref={ref} className={'dsg-input ' + className} style={{ pointerEvents: focus ? 'auto' : 'none' }} type='date' value={rowData[column] && rowData[column] !== 0 ? rowData[column] : ''} onChange={(e) => setRowData({ ...rowData, [column]: e.target.value })} />
              <ReactTooltip id={rowIndex + '-' + columnIndex} type="error" effect="solid">
                <span><b>Please select a date</b></span>
              </ReactTooltip>
            </>
          )
        } else {
          return (<input ref={ref} className={'dsg-input ' + className} style={{ pointerEvents: focus ? 'auto' : 'none' }} type='date' value={rowData[column] ? rowData[column] : ''} onChange={(e) => setRowData({ ...rowData, [column]: e.target.value })} />);
        }
      case 'select':
        if (!rowData[column]) rowData[column] = "";
        if (className === 'missing-value') {
          return (
            <>
              <select
                data-for={rowIndex + '-' + columnIndex}
                data-tip
                ref={ref}
                className={'dsg-input ' + className}
                style={{ pointerEvents: focus ? 'auto' : 'none' }}
                value={rowData[column]}
                onChange={(e) => setRowData({ ...rowData, [column]: e.target.value })}
              >
                <option default></option>
                {selectData.map((item, index) => {
                  return <option key={index}>{item}</option>
                })}
              </select>
              <ReactTooltip id={rowIndex + '-' + columnIndex} type="error" effect="solid">
                <span><b>Please select breed type</b></span>
              </ReactTooltip>
            </>
          )
        } else {
          return (
            <select
              ref={ref}
              className={'dsg-input ' + className}
              style={{ pointerEvents: focus ? 'auto' : 'none' }}
              value={rowData[column]}
              onChange={(e) => setRowData({ ...rowData, [column]: e.target.value })}
            >
              <option default></option>
              {selectData.map((item, index) => {
                return <option key={index}>{item}</option>
              })}
            </select>
          )
        }

      case 'number':
        if (className === 'missingValue') {
          return (
            <>
              <input data-for={rowIndex + '-' + columnIndex} data-tip="Please enter a number" type='number' min='0' max='9999' ref={ref} className={'dsg-input ' + className} style={{ pointerEvents: focus ? 'auto' : 'none' }} value={rowData[column]} onChange={(e) => setRowData({ ...rowData, [column]: e.target.value })} />
              <ReactTooltip id={rowIndex + '-' + columnIndex} type="error" effect="solid" />
            </>
          )
        } else {
          return (<input type='number' min='0' max='9999' ref={ref} className={'dsg-input ' + className} style={{ pointerEvents: focus ? 'auto' : 'none' }} value={rowData[column]} onChange={(e) => setRowData({ ...rowData, [column]: e.target.value })} />);
        }
      case 'check':
        return (
          <input className='dsg-checkbox' type='checkbox' ref={ref} checked={Boolean(rowData[column])} onChange={(e) => setRowData({ ...rowData, [column]: e.target.checked ? true : false })} />
        )
      default:
        break;
    }
  }

  if (data.length > 0) {
    return (
      <div className='spreadsheet'>
        <DataSheetGrid
          data={data}
          onChange={(d) => handleChange(d)}
          columns={columns}
          height={height - 150}
        />
      </div>
    )
  } else {
    return (
      <div className="empty-table">
        <h2>It seems quiet here. 🙄</h2><br />
        <span>Please select a farm from up left</span>
      </div>
    )
  }
}

export default connect(Spreadsheet);
import React from 'react';
import connect from '../../containers/Upload';


export function Upload({ data, farm, updates, cowTypes, updateCow, insertCow, removeCow, sendEvent, tableStatus, removedCows }) {

    if (removedCows.length > 0) {
        document.querySelector("#deleteCowOnUpdate").style.display = "flex";
    }

    const upload = () => {
        if (tableStatus === 'ready') {
            let updateData = Object.values(data).filter((cow) => {

                if (removedCows.length > 0) {
                    return updates.some((item) => {
                        return item.cowName === cow.identityNumber && item.newCow === false;
                    }) && removedCows.some((item) => {
                        return item.identityNumber !== cow.identityNumber;
                    });
                } else {
                    return updates.some((item) => {
                        return item.cowName === cow.identityNumber && item.newCow === false;
                    });
                }
            });

            let insertData = Object.values(data).filter((cow) => {
                if (removedCows.length > 0) {
                    return updates.some((item) => {
                        return item.cowName === cow.identityNumber && item.newCow === true;
                    }) && removedCows.some((item) => {
                        return item.identityNumber !== cow.identityNumber;
                    });
                } else {
                    return updates.some((item) => {
                        return item.cowName === cow.identityNumber && item.newCow === true;
                    });
                }

            });


            if (Object.keys(updateData).length > 0) {
                updateData.map((data) => {
                    data.lactationCount = data.lactationCount || 0;
                    data.lastBreedingDate = data.lastBreedingDate || 0;
                    data.lastCalvingDate = data.lastCalvingDate || 0;
                    data.birthdate = data.birthdate || 0;
                });
                if (updateData.length >= 250) {
                    let chunk = 0;
                    while (chunk <= updateData.length) {
                        updateCow(updateData.slice(chunk, chunk + 250));
                        chunk += 250;
                    }
                } else {
                    updateCow(updateData);
                }
            }
            if (Object.keys(insertData).length > 0) {
                insertData.map((data) => {
                    data.lactationCount = data.lactationCount || 0;
                    data.lastBreedingDate = data.lastBreedingDate || 0;
                    data.lastCalvingDate = data.lastCalvingDate || 0;
                    data.birthdate = data.birthdate || 0;
                });
                if (insertData.length >= 250) {
                    let chunk = 0;
                    while (chunk <= insertData.length) {
                        insertCow(insertData.slice(chunk, chunk + 250));
                        chunk += 250;
                    }
                } else {
                    insertCow(insertData);
                }
            }

            const shouldRemoved = document.querySelector("#checkDeleteCowOnUpdate").checked;
            if (removedCows.length > 0) {
                removeCow(removedCows, shouldRemoved);
            }

            document.querySelector("#sendEvent").style.display = 'flex';
            document.querySelector("#deleteCowOnUpdate").style.display = 'none';
        } else {
            alert('Please check the table');
        }

    }

    const sendEventHandler = () => {
        sendEvent(farm.id);
        document.querySelector("#sendEvent").style.display = 'none';
    }

    return (
        <div className='buttons'>
            <div id="deleteCowOnUpdate">
                <label>Delete cow on update?</label>
                <input id="checkDeleteCowOnUpdate" type="checkbox" value="Delete cow on update" />
            </div>
            <input id="sendEvent" className="navEvent" type="button" value="Send List Update Event" onClick={sendEventHandler} />
            <input id="saveChanges" className="navSave" type="button" value="Save Changes" onClick={upload} />
        </div>
    )
}

export default connect(Upload);
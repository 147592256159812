import React from 'react';
import connect from '../containers/DateFormat';

export function DateFormat({ dateFormat, setFormat }) {


    return (
        <div>
            <label htmlFor="format-input">Date Format:</label>
            <input id="format-input" type="text" maxLength={10} defaultValue={dateFormat} onChange={(e) => setFormat(e.target.value)} />
        </div>
    )
}

export default connect(DateFormat);
import React from 'react';
import connect from '../../containers/SelectFile';
import '../Navigation/Navigation.css';
import XLSX from 'xlsx';

export function SelectFile({ selectFile, dataChange, format }) {

    const customFormat = (string) => {
        const formatArray = format.split(/[./-]/g);
        const day = formatArray.findIndex(exp => exp === "d" || exp === "dd" || exp === "d" || exp === "DD");
        const month = formatArray.findIndex(exp => exp === "m" || exp === "mm" || exp === "mmm" || exp === "M" || exp === "MM" || exp === "MMM");
        const year = formatArray.findIndex(exp => exp === "yy" || exp === "yyyy" || exp === "YY" || exp === "YYYY");
        const stringArray = string.split(/[./-]/g);
        return new Date(`${stringArray[month]}/${stringArray[day]}/${stringArray[year]}`).getTime();

    }

    const stringToDate = (string) => {
        if (!string || string === "") {
            return 0;
        } else {
            return parseInt(customFormat(string)) + 43200000 || 0;
        }
    }

    const readCsvFile = (file) => {
        var reader = new FileReader();
        reader.onload = function (e) {
            const text = e.target.result;
            const lines = text.split('\n');
            const result = [];
            const headersInit = lines[0].split(';');

            const headers = headersInit.map((item) => {
                if (item === 'KULAK NO') return 'identityNumber';
                if (item === 'İNEK NO') return 'farmNumber';
                if (item === 'İNEK TANIM') return 'name';
                if (item === 'İNEK CİNSİ') return 'breed';
                if (item === 'İNEK CİNSİYETİ') return 'gender';
                if (item === 'DOĞUM TARİHİ') return 'birthdate';
                if (item === 'SON BUZAĞILAMA TARİHİ') return 'lastCalvingDate';
                if (item === 'LAKTASYON SAYISI') return 'lactationCount';
                if (item === 'SON TOHUMLAMA TARİHİ') return 'lastBreedingDate';
                if (item === 'PADOK NO') return 'padokNo';
                if (item === 'GEBE') return 'isPregnant';
                if (item === 'ÜREME DURUMU NOT 1') return 'breedingStatusNote';
                if (item === 'DOĞURMA TARİHİ') return 'expectedDelivery';
            });

            lines.splice(0, 1);
            lines.forEach((line) => {
                let obj = {};
                let currentLine = line.split(';');
                headers.forEach((header, index) => {
                    if (header === 'birthdate' ||
                        header === 'lastCalvingDate' ||
                        header === 'lastBreedingDate' ||
                        header === 'expectedDelivery') {
                        obj[header] = stringToDate(currentLine[index]);
                    } else {
                        obj[header] = currentLine[index];
                    }
                });
                if (!obj['lastBreedingDate']) obj['lastBreedingDate'] = obj['expectedDelivery'] - 24192000000;
                obj['breedingStatusNote'] === 'gebe' ? obj['isPregnant'] = true : obj['isPregnant'] = false;
                result.push(obj);
            });
            selectFile(result);
            dataChange(result);
        };
        reader.readAsText(file);
    }

    const readExcelFile = (file) => {

        var f = file;
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            // Convert to JSON
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, defval: null });
            const columns = dataParse.shift();
            const result = dataParse.map(function (data) {
                let cow = data.reduce(function (result, field, index) {
                    if (columns[index] === 'KULAK NO') {
                        // field = field.replace(/\s+/g, '');
                        result['identityNumber'] = field;
                    } else if (columns[index] === 'İNEK NO') {
                        result['farmNumber'] = field;
                    } else if (columns[index] === 'İNEK TANIM') {
                        if (field && field.length > 0)
                            result['name'] = field;
                    } else if (columns[index] === 'İNEK CİNSİ') {
                        if (field)
                            result['breed'] = field;
                    } else if (columns[index] === 'İNEK CİNSİYETİ') {
                        if (field)
                            result['gender'] = field;
                    } else if (columns[index] === 'DOĞUM TARİHİ') {
                        if (field)
                            result['birthdate'] = stringToDate(field);
                    } else if (columns[index] === 'SON BUZAĞILAMA TARİHİ') {
                        if (field)
                            result['lastCalvingDate'] = stringToDate(field);
                    } else if (columns[index] === 'LAKTASYON SAYISI') {
                        if (field)
                            result['lactationCount'] = field;
                    } else if (columns[index] === 'SON TOHUMLAMA TARİHİ') {
                        if (field)
                            result['lastBreedingDate'] = stringToDate(field);
                    } else if (columns[index] === 'PADOK NO') {
                        if (field)
                            result['padokNo'] = field;
                    } else if (columns[index] === 'GEBE') {
                        if (field)
                            result['isPregnant'] = field;
                    } else if (columns[index] === 'ÜREME DURUMU NOT 1') {
                        if (field)
                            result['breedingStatusNote'] = field;
                    } else if (columns[index] === 'DOĞURMA TARİHİ') {
                        if (field)
                            result['expectedDelivery'] = stringToDate(field);
                    }
                    return result;
                }, {});
                // if(!cow['lastBreedingDate']) cow['lastBreedingDate'] = cow['expectedDelivery'] - 24192000000;
                cow['breedingStatusNote'] === 'gebe' ? cow['isPregnant'] = true : cow['isPregnant'] = false;
                return cow;
            });

            selectFile(result);
            dataChange(result);
        };
        reader.readAsBinaryString(f);
    }

    const readFile = (e) => {
        const file = e.target.files[0];
        const name = file.name;
        const lastDot = name.lastIndexOf('.');
        const ext = name.substring(lastDot + 1);

        if (ext === 'csv') readCsvFile(file);
        else readExcelFile(file);
    }

    return (
        <div>
            <input type="file" id="file" className="navButton" accept=".csv,.xls,.xlsx" onChange={readFile} />
        </div>
    );
}

export default connect(SelectFile);
import { connect } from 'react-redux';
import { updateCow, insertCow, sendEvent, removeCow } from '../store/actions';

function mapStateToProps({ cows, farms }) {
    return {
        data: cows.data,
        updates: cows.updates,
        cowTypes: cows.cowTypes,
        removedCows: cows.removedCows,
        farm: farms.selectedFarm
    };
}
const mapDispatchToProps = { updateCow, insertCow, removeCow, sendEvent };
export default connect(mapStateToProps,mapDispatchToProps);
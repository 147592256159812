export const missingValue = (row, column) => {
    document.querySelector(`div[row='${row}'] div[column='${column}']`).style.backgroundColor = 'rgba(255,0,0,0.6)';
}

export const updatedValue = (row, column) => {
    document.querySelector(`div[row='${row}'] div[column='${column}']`).style.backgroundColor = 'rgba(199,184,26,0.6)';
}

export const newValue = (row, column) => {
    document.querySelector(`div[row='${row}'] div[column='${column}']`).style.backgroundColor = 'rgba(132,187,114,0.6)';
}

export const noChange = (row, column) => {
    document.querySelector(`div[row='${row}'] div[column='${column}']`).style.backgroundColor = 'white';
}

export const showSaveChanges = () => {
    document.querySelector("#saveChanges").style.display = 'inline';
}

export const hideSaveChanges = () => {
    document.querySelector("#saveChanges").style.display = 'none';
}

export const showSelectFile = () => {
    document.querySelector("#file").style.display = 'block';
}

export const hideSelectFile = () => {
    document.querySelector("#file").style.display = 'none';
}

export const highlightStart = () => {
    document.querySelector("#farm-select").setAttribute('class', 'highlight');
}

export const highlightStop = () => {
    document.querySelector("#farm-select").setAttribute('class', '');
}

export const findCow = (cow, target) => {
    return target.find(o => o.cowName === cow);
}

export const getPrimaryColumns = () => {
    return ['identityNumber', 'farmNumber', 'breed', 'birthdate', 'isPregnant'];
}

export const hasMissingValue = (value) => {
    return value === '' || value === 0 || value === null || value === undefined ? true : false;
}

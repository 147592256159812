import { put, take, spawn } from 'redux-saga/effects';
import axios from 'axios';

import {
    SKIP_LOGIN_REQUEST,
    COMPLETE_LOGIN_REQUEST,
    FAIL_LOGIN_REQUEST
} from "../actions/actionTypes";

import { login, getFarms, getCowTypesRequest } from '.';

export function* startup() {
    yield spawn(login);
    const loginResult = yield take([SKIP_LOGIN_REQUEST, COMPLETE_LOGIN_REQUEST, FAIL_LOGIN_REQUEST]);

    if (loginResult.type === FAIL_LOGIN_REQUEST) {
        return yield put({ ...loginResult });
    }
    yield setDefaultAxiosHeaders();
    yield spawn(getFarms);
    yield spawn(getCowTypesRequest);
}

function setDefaultAxiosHeaders() {
    const LTT = localStorage.getItem("longTermToken");
    axios.defaults.headers.Authorization = `Bearer ${LTT}`;
}
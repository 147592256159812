import axios from 'axios';
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        console.log("request error", error);
        return Promise.reject(error);
    },
);

axios.defaults.baseURL = window._env_.API_HOST;
// axios.defaults.headers.Authorization = 'Bearer zDS5QfwHHktbIEClmMPvXOIbh1QrBHhZUCUWKMbUga2HSY01OA';
import { put, take, spawn, call, takeEvery } from "redux-saga/effects";
import {
    GET_COW_TYPES,
    GET_PREGNANCY_STATUS,
    GET_FARMS,
    LOAD_TABLE,
    SELECT_FARM,
    SET_COWS,
    SEND_EVENT
} from '../actions/actionTypes';
import axios from 'axios';

export function* getFarms() {
    const response = yield call(axios.get, "/users/farms");
    yield put({ type: GET_FARMS, farms: response.data });
}

export function* farmData() {
    const { farm } = yield take(SELECT_FARM);
    yield (farmDataGetRequest(farm.id));
    yield spawn(farmData);
}

function* farmDataGetRequest(farmID) {
    const apiCall = () => {
        return axios.get('/cows', { headers: { 'X-Wicow-FarmID': farmID } })
            .then(response => {
                axios.defaults.headers["X-Wicow-FarmID"] = farmID;
                return response.data;
            })
            .catch((error) => {
                console.log('Error: ' + error);
            });
    }
    try {
        const response = yield (apiCall());
        const status = yield call(axios.get, "/pregnancyStatus");

        yield put({ type: GET_PREGNANCY_STATUS, pregnancyStatus: status.data });
        yield put({ type: LOAD_TABLE, cows: response });
        yield put({ type: SET_COWS, cows: response });
    } catch (error) {
        console.log('An error occured while fetching farm: ', error);
    }
}

export function* getCowTypesRequest() {
    const apiCall = () => {
        return axios.get('/cowTypes', { headers: { 'X-Wicow-FarmID': 1 } })
            .then(res => res.data)
            .catch((err) => {
                console.log('Error: ' + err);
            });
    }
    try {
        const response = yield (apiCall());
        yield put({ type: GET_COW_TYPES, cowTypes: response });
    } catch (error) {
        console.log('An error occured while fetching cow types: ', error);
    }
}

export function* sendEvent() {
    yield takeEvery(SEND_EVENT, sendEventHandler);
}

export function* sendEventHandler() {
    const action = yield take(SEND_EVENT);
    try {
        yield call(axios.post, "/v2/events", {
            "event": "cow-list-updated",
            "payload": {
                "farm": action.farm
            }
        });
    } catch (error) {
        console.log('An error occured while sending list update event: ', error);
    }
}
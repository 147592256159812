import {
    COMPLETE_LOGIN_REQUEST,
    SKIP_LOGIN_REQUEST,
} from "../actions/actionTypes";

export default function longTermToken(state = null, action) {
    switch (action.type) {
        case COMPLETE_LOGIN_REQUEST:
            return action.longTermToken;
        case SKIP_LOGIN_REQUEST:
            return action.longTermToken;
        default:
            return state;
    }
}

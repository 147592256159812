import React, { useState } from 'react';
import { FAILED, DONE, PENDING } from '../../constants/lifecycle';
import { login as connect } from '../../containers';
import './LoginForm.css'
const Login = ({ lifecycle, submitLoginEmail }) => {
    const [email, setEmail] = useState("");

    const handleChange = (e) => {
        setEmail(e.target.value);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (email) {
            submitLoginEmail(email);
        }
    }

    const lifeCycleFormatter = (status) => {
        switch (status) {
            case PENDING:
                return (
                    <div className='form-lifecycle' >
                        <h3>Please wait...</h3>
                    </div >
                );
            case DONE:
                return (
                    <div className='form-lifecycle'>
                        <h3 className='success'>✅ <br /> Login link is sent. Please check your mail inbox.</h3>
                    </div>
                );
            case FAILED:
                return (
                    <div className='form-lifecycle'>
                        <h3 className='error'>⛔️ <br /> Something went wrong. Please check your email and try again.</h3>
                    </div>
                );
            default:
                return (
                    <div className='form-lifecycle'>
                        <form className='form' onSubmit={handleSubmit}>
                            <input className='form-input' id="form-mail" type={"email"} onChange={handleChange} placeholder={"E-mail"} />
                            <button className='form-input' id="form-button" type={"submit"}>Submit</button>
                        </form>
                    </div>
                );
        }
    }

    return (
        <div className='form-container'>
            <h2>Login</h2>
            {lifeCycleFormatter(lifecycle)}
        </div>
    )
}

export default connect(Login);
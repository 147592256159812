/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import { 
    DATA_CHANGE, 
    SELECT_FILE, 
    LOAD_TABLE, 
    SET_COWS, 
    GET_COW_TYPES,
    GET_PREGNANCY_STATUS,
    MANUAL_DATA_CHANGE, 
    UPDATE_COW_SUCCESS, 
    INSERT_COW_SUCCESS, 
    REMOVE_COW_SUCCESS} from "../actions/actionTypes";

const fakeRow = createEmptyRows(40);

const initialState = {
    // Data to show
    data: [], 
    
    // Database data
    cows: [],

    // Cow types
    cowTypes: [],

    // Pregnancy Status
    pregnancyStatus: [],

    // Excel data
    excelData: [],

    // Changed cows / attributes
    updates: [],

    // Duplicate watcher
    duplicates: [],

    // Update/insert response
    response: [],

    removedCows: [],
}
export default function (state = initialState, action) {
    switch (action.type) {

        case LOAD_TABLE: 
            // Load from database
            state.data = [];
            state.updates = [];
            state.excelData = [];
            
            if (action.cows.length > 0) {
                action.cows.map(cow => {
                    cow['isPregnant'] = mapCowWithPregnancyStatus(cow, state.pregnancyStatus);
                });
                return { ...state, data: action.cows };
            } else {
                return {...state, data: fakeRow};
            }
            
        case SELECT_FILE:
            // Load from Excel table
            return { ...state, excelData: action.file };

        case SET_COWS:
            state.cows = [];
            action.cows.map(cow => {
                cow['isPregnant'] = mapCowWithPregnancyStatus(cow, state.pregnancyStatus);
            });
            if (action.cows.length > 0) {
                return { ...state, cows: action.cows };
            } else {
                return {...state, cows: []};
            }

        case GET_COW_TYPES: 
            return { ...state, cowTypes: action.cowTypes };
        
        case GET_PREGNANCY_STATUS:
            state.pregnancyStatus = {}
            return {...state, pregnancyStatus: action.pregnancyStatus};
            
        case DATA_CHANGE:
            //logic
            let updatedCows = [];
            let oldCows = [...state.cows];
            let newCows = action.data;
            
            newCows.map(cow => {
                if (isCowExist(cow.identityNumber, oldCows)) {
                    let changedAttributes = {};
                    let index = oldCows.findIndex(x=> x.identityNumber === cow.identityNumber);
                    cow.id = oldCows[index].id;
                    Object.keys(cow).map(attr => {
                        if (cow[attr] !== "") {
                            if (attr === 'birthdate' || attr === 'lastBreedingDate' || attr === 'lastCalvingDate' || attr === 'expectedDelivery') {
                                cow[attr] = dateToUnixConvert(cow[attr], true);
                                if (cow[attr] === 0){
                                    cow[attr] = oldCows[index][attr];
                                } else {
                                    let newCowDate = new Date(cow[attr]).toISOString().split('T')[0];
                                    let oldCowDate = new Date(oldCows[index][attr]).toISOString().split('T')[0];
                                    if (newCowDate !== oldCowDate)
                                        changedAttributes[attr] = cow[attr];
                                }
                            }
                            else if (cow[attr] !== oldCows[index][attr])
                                changedAttributes[attr] = cow[attr];
                        }
                    });
                    if (Object.keys(changedAttributes).length !== 0)
                        updatedCows.push({ cowName: cow.identityNumber, newCow: false, attributes: changedAttributes });
                    
                    oldCows[index] = { ...oldCows[index], ...cow };
                } else {
                    oldCows.push(cow);
                    updatedCows.push({ cowName: cow.identityNumber, newCow: true });
                }
            });
            return { ...state, data: oldCows, updates: updatedCows};
        
        case MANUAL_DATA_CHANGE:
            let manualUpdatedCows = [];
            let duplication = [];
            let manualOldCows = state.cows;
            let manualNewCows = action.data;

            const removedCows = manualOldCows.filter(({id: id1}) => !manualNewCows.some(({id: id2})=> id2 === id1));

            manualNewCows.map((cow, index) => {
                // Assignment
                cow = resetCow(cow);

                // Delete spaces
                if (cow.identityNumber && cow.identityNumber.length > 0) cow.identityNumber = cow.identityNumber.replace(/\s+/g, '');

                // Check duplicates
                if ((manualNewCows.filter(o => o.identityNumber === cow.identityNumber)).length > 1) {
                    if (cow.identityNumber !== "") {
                        let idNo = state.data[index].identityNumber;
                        cow.identityNumber = idNo;
                        duplication.push(cow.identityNumber);
                    } else {
                        cow = resetCow(cow);
                    }
                }
            });

            manualNewCows.map((cow) => {
                if (cow.identityNumber !== ""){
                    if (isCowExist(cow.identityNumber, manualOldCows)) {
                        let changedAttributes = {};
                        const index = manualOldCows.findIndex((x) => x.identityNumber === cow.identityNumber);

                        Object.keys(cow).map((attr) => {
                            if (attr === 'birthdate' || attr === 'lastBreedingDate' || attr === 'lastCalvingDate' || attr === 'expectedDelivery') {
                                cow[attr] = cow[attr] !== 0 ? dateToUnixConvert(cow[attr], true) : 0;
                                let isDatesEqual = compareDates(cow[attr], manualOldCows[index][attr]);
                                if (!isDatesEqual) {
                                    changedAttributes[attr] = cow[attr];
                                }
                            } else if (cow[attr] !== manualOldCows[index][attr]) {
                                changedAttributes[attr] = cow[attr];
                            }
                        });
                        
                        if (Object.keys(changedAttributes).length > 0) {
                            manualUpdatedCows.push({ cowName: cow.identityNumber, newCow: false, attributes: changedAttributes });
                        }
                    } else {
                        Object.keys(cow).map((attr) => {
                            if (attr === 'birthdate' || attr === 'lastBreedingDate' || attr === 'lastCalvingDate' || attr === 'expectedDelivery') {
                                cow[attr] = dateToUnixConvert(cow[attr], true);
                            }
                        });
                        manualUpdatedCows.push({ cowName: cow.identityNumber, newCow: true });
                    }
                }
            })
            manualOldCows = null;
            return { ...state, data: manualNewCows, updates: manualUpdatedCows, duplicates: duplication, removedCows: removedCows};

        case INSERT_COW_SUCCESS:
            return { 
                ...state,
                cows: state.data,
                updates: [], 
                response: action.result,
            };

        case UPDATE_COW_SUCCESS:
            return { 
                ...state,
                cows: state.data,
                updates: [], 
                response: action.result,
            };
        case REMOVE_COW_SUCCESS:
            return {
                ...state,
                cows: state.data,
                removedCows: [],
                response: action.result,
            };
        default: return state;
    }
}

function isCowExist(cow, table) {
    return table.find(x => x.identityNumber === cow) ? true : false;    
}

function dateToUnixConvert(attr, addHours=false) {
    if (addHours) {
        return (attr !== 0 && attr !== undefined && attr !== null && attr !== "") ? parseInt(new Date(attr).getTime()) + 32400000 : 0;
    } else {
        return (attr !== 0 && attr !== undefined && attr !== null && attr !== "") ? parseInt(new Date(attr).getTime()) : 0;
    }
}

function compareDates(date1, date2) {
    if (isNaN(date1) || isNaN(date2)) return false;
    let d1 = new Date(date1).toISOString().split('T')[0];
    let d2 = new Date(date2).toISOString().split('T')[0];
    return d1 === d2 ? true : false;
}

function mapCowWithPregnancyStatus (cow, table) {
    if (table && table[cow.id]) {
        if (table[cow.id].includes('pregnant')){
            return true;
        }
    }
    return false;
}

function resetCow (cow) {
    cow.identityNumber = cow.identityNumber || '';
    cow.farmNumber = cow.farmNumber || '';
    cow.name = cow.name || '';
    cow.breed = cow.breed || '';
    cow.birthdate = cow.birthdate || 0;
    cow.lastCalvingDate = cow.lastCalvingDate || '';
    cow.lactationCount = cow.lactationCount || 0;
    cow.lastBreedingDate = cow.lastBreedingDate || 0;
    cow.expectedDelivery = cow.expectedDelivery || ''; 
    cow.isPregnant = cow.isPregnant || false;
    return cow;
}

function createEmptyRows(count) {
    let obj = {};
    for (let i = 0; i < count; i++) {
        obj[i] = resetCow({});
    }
    return obj;
}
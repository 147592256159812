import { put, takeEvery, take, call } from 'redux-saga/effects';
import {
    UPDATE_COW,
    INSERT_COW,
    REMOVE_COW,
    UPDATE_COW_SUCCESS,
    INSERT_COW_SUCCESS,
    REMOVE_COW_SUCCESS,
} from '../actions/actionTypes';
import axios from 'axios';

export function *operation() {
    yield takeEvery(UPDATE_COW, updateCow);
    yield takeEvery(INSERT_COW, insertCow);
    yield takeEvery(REMOVE_COW, removeCow);
}

export function *updateCow() {
    const action = yield take(UPDATE_COW);
    try {
        const response = yield call(axios.post, "/importer/update", action.data);
        yield put({ type: UPDATE_COW_SUCCESS, result: response });
    } catch (error) {
        console.log('An error occured while updating cow: ', error);
    }
}

export function *insertCow() {
    const action = yield take(INSERT_COW);
    try {
        const response = yield call(axios.post, "/importer/insert", action.data);
        yield put({ type: INSERT_COW_SUCCESS, result: response });
    } catch (error) {
        console.log('An error occured while adding cow: ', error);
    }
}

export function *removeCow() {
    const action = yield take(REMOVE_COW);
    if (action.shouldRemoved) {
        try {
            const response = yield call(axios.post, "/importer/remove", action.data);
            yield put({ type: REMOVE_COW_SUCCESS, result: response });
        } catch (error) {
            console.log("An error occcured while deleting cow: ", error);
        }
    } else {
        yield put({ type: REMOVE_COW_SUCCESS, result: "Bypassed" });
    }
}

import React, { useState } from 'react';
import { connect } from 'react-redux';
import './App.css';
import Navigation from '../components/Navigation';
import LoginModal from '../components/LoginModal';
import Spreadsheet from '../components/Spreadsheet';


function App({ loginStatus, longTermToken }) {
  const [tableStatus, setTableStatus] = useState('not-ready');
  const isLoggedIn = loginStatus !== "failed" && longTermToken;
  return (
    <div className="main">
      <Navigation loggedIn={isLoggedIn} tableStatus={tableStatus} />
      {isLoggedIn ? <Spreadsheet setTableStatus={setTableStatus} /> : <LoginModal />}
    </div>
  );
}

function mapStateToProps({ longTermToken, login: { loginStatus } }) {
  return {
    loginStatus, longTermToken
  };
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(App);
